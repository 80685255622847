import React, {useContext, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Unauthorized} from './components/layout/Unauthorized';
import {ZohoContext} from './context/app/ZohoContext';
import {PurchasingState} from './context/purchasing/PurchasingState';
import {MenuTypes, ModuleTypes} from './model/constants/Constants';
import Home from './screens/Home';
import Onboarding from './screens/Onboarding';
import {NotFound} from './screens/public';
import {TakePayment} from './screens/sales/TakePayment';
import {Settings} from './screens/Settings';
import {CustomerInvitation} from './screens/public/CustomerInvitation';
import {Callback} from './components/layout/Callback';
import {PayByLink} from './screens/pay-by-link/PayByLink';
import {PayByLinkInvoice} from './screens/pay-by-link/PayByLinkInvoice';

import {
  AUTH_SELLING_COLLECT_ROUTE,
  AUTH_TENANT_SETUP,
  AUTH_PAYMENT_AUTHORISATION,
  AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE,
  AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE,
  AUTH_SELLING_AR_EDIT_CLAIM_REQUESTS_ROUTE,
  AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE,
  AUTH_SELLING_AR_PAID_INVOICES_ROUTE,
  AUTH_MERCHANT_PORTAL_ROUTE,
  AUTH_TRADE_ACCOUNT_ROUTE,
} from './routes/AuthenticatedRoutes';
import AppContext from './context/app/appContext';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {Menu} from './screens/menu/Menu';
import {FeatureFlagKeys} from './hooks/useFeatureFlags';
import APOnboarding from './screens/purchasing/AccountsPayableOnboarding';
import {TenantSetupScreen} from './screens/TenantSetup';
import {Referral} from './screens/public/Referral';
import {AuthorisePayment} from './screens/payment-authority/AuthorisePayment';
import {IntegrationContext} from './context/IntegrationContext';
import {ClaimsAndReturns} from './screens/AccountsReceivable/claims-and-returns/ClaimsAndReturns';
import APContext from './context/ap-context/APContext';
import {PayByLinkProvider} from './screens/pay-by-link/PayByLinkContext';
import {MarketplaceLayout} from './screens/purchasing/MarketplaceLayout';
import {PrivateRoute} from './routes/PrivateRoute';
import {MarketplaceRoute, MarketplaceRoutes} from './routes/MarketplaceRoute';
import {InventoryRoutes} from './routes/InventoryRoutes';
import {CustomerRoutes} from './routes/CustomerRoutes';
import {SalesOrderRoutes} from './routes/SalesOrderRoutes';
import {AccountsPayableRoutes} from './routes/AccountsPayableRoutes';
import {PurchasingRoutes} from './routes/PurchasingRoutes';
import CreateAndEditClaimRequest from './screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import {ClaimByLink} from './screens/pay-by-link/ClaimByLink';
import {SupplierManagementRoutes} from './routes/SupplierManagementRoutes';
import {AccountsPayableModuleInfo} from './screens/module-info/AccountsPayableModuleInfo';
import APOnboardingV1 from './screens/onboarding/APOnboardingV1';
import {AUTH_FINANCE_SERVICES, FinanceServiceRoutes} from './routes/FinanceServiceRoutes';
import ARPaymentsScreen from './screens/AccountsReceivable/payments/ARPaymentsScreen';
import {QuoteManagementRoutes} from './routes/QuoteManagementRoutes';
import {ServiceManagementRoutes} from './routes/ServiceManagementRoutes';
import {PaidInvoices} from './screens/AccountsReceivable/paid-invoices/PaidInvoices';
import {MerchantPortal} from './screens/MerchantPortal/MerchantPortal';
import {TransactionHistory} from './screens/ECommerce/TransactionHistory';
import {ApplicationInfo, CapricornApplicationInfo} from './model/constants/ApplicationInfo';

export const AuthenticatedApp: React.FC = () => {
  // Context
  const {user} = useContext(AppContext);
  const zohoChatContext = useContext(ZohoContext)!;
  const {getAdaptors, setAdaptors} = useContext(IntegrationContext);

  const {
    getUserRoleBatchPermissions,
    setUserRoleBatchPermissions,
    getUserDefaultViewSettings,
    setUserDefaultViewSettings,
  } = useContext(APContext);

  // Hooks
  const client = useLDClient();

  useEffect(() => {
    let mounted = true;
    const getDetailsAsync = async () => {
      const [adaptorResult, userPermissions, userSettings] = await Promise.all([
        getAdaptors(),
        getUserRoleBatchPermissions(),
        getUserDefaultViewSettings(),
      ]);
      if (mounted) {
        setAdaptors(adaptorResult);
        setUserRoleBatchPermissions(userPermissions);
        setUserDefaultViewSettings(userSettings);
      }
    };

    getDetailsAsync();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (client?.identify) {
      const u = {
        key: user?.TenantID?.toString(),
      };
      client.identify(u);
    }
  }, []);

  useEffect(() => {
    if (zohoChatContext.isReady && user) {
      zohoChatContext.loadUser(user!);
    }
  }, [zohoChatContext.isReady]);

  return (
    <PurchasingState>
      <PayByLinkProvider>
        <Switch>
          <Route path="/statement" exact component={CustomerInvitation} />
          <Route path="/invoices" exact component={PayByLink} />
          <Route path="/invoices/pibl/:magicLink" exact component={PayByLinkInvoice} />
          <Route path="/invoices/claim/:magicLink" exact component={ClaimByLink} />
          <Route path="/invite/referral/:referrer" exact component={Referral} />

          <PrivateRoute
            path="/"
            exact
            websiteIds={[ApplicationInfo.WebsiteId, CapricornApplicationInfo.WebsiteId]}
            featureFlag={FeatureFlagKeys.payStatementByLinkV289938}
          />

          <PrivateRoute
            path={AUTH_SELLING_COLLECT_ROUTE}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={TakePayment}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            key={'arPayments'}
            Component={ARPaymentsScreen}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE}/:customerId`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            key={'arPaymentByCustomer'}
            Component={ARPaymentsScreen}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_COLLECT_ROUTE}/:customerId`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={TakePayment}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={AUTH_SELLING_COLLECT_ROUTE}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={TakePayment}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={ClaimsAndReturns}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
            featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_AR_EDIT_CLAIM_REQUESTS_ROUTE}/:claimRequestId`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={CreateAndEditClaimRequest}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
            featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE}`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={CreateAndEditClaimRequest}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
            featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_COLLECT_ROUTE}/:customerId/:transactionId`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            Component={TakePayment}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={AUTH_SELLING_AR_PAID_INVOICES_ROUTE}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            key={'paidInvoices'}
            Component={PaidInvoices}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={`${AUTH_SELLING_AR_PAID_INVOICES_ROUTE}/:customerId`}
            exact
            moduleIds={[ModuleTypes.SpendaCollectAR]}
            key={'paidInvoicesByCustome'}
            Component={PaidInvoices}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.SpendaMarketSelling,
              ModuleDependency: [ModuleTypes.SpendaCollectAR],
            }}
          />
          <PrivateRoute
            path={AUTH_MERCHANT_PORTAL_ROUTE}
            exact
            moduleIds={[ModuleTypes.MerchantPortal]}
            Component={MerchantPortal}
            MenuFilters={{
              MenuType: MenuTypes.Selling,
              ModuleID: ModuleTypes.MerchantPortal,
              ModuleDependency: [ModuleTypes.MerchantPortal],
            }}
            featureFlag={FeatureFlagKeys.payfacMerchantPortal88470}
          />
          <PrivateRoute
            path={AUTH_TRADE_ACCOUNT_ROUTE}
            exact
            moduleIds={[ModuleTypes.TradeAccount]}
            Component={TransactionHistory}
            MenuFilters={{
              MenuType: MenuTypes.Buying,
              ModuleID: ModuleTypes.TradeAccount,
              ModuleDependency: [ModuleTypes.TradeAccount],
            }}
            featureFlag={FeatureFlagKeys.eCommerceAndTransactionsView89915}
          />
          <Route path={AUTH_FINANCE_SERVICES}>
            <FinanceServiceRoutes />
          </Route>

          <Route path="/accounts-payable">
            <AccountsPayableRoutes />
          </Route>

          <Route path="/manage-supplier">
            <SupplierManagementRoutes />
          </Route>

          <Route path="/customers">
            <CustomerRoutes />
          </Route>

          <Route path="/inventory">
            <InventoryRoutes />
          </Route>

          <Route path="/sales-orders">
            <SalesOrderRoutes />
          </Route>

          <Route path="/purchasing">
            <PurchasingRoutes />
          </Route>

          <Route path="/manage-quotes">
            <QuoteManagementRoutes />
          </Route>

          <Route path="/service">
            <ServiceManagementRoutes />
          </Route>

          {/* Payment Authority Routes */}
          <PrivateRoute
            path={AUTH_PAYMENT_AUTHORISATION}
            exact
            Component={AuthorisePayment}
            moduleIds={[ModuleTypes.SpendaCollectConnectedStatements, ModuleTypes.SpendaPay]}
            featureFlag={FeatureFlagKeys.paymentAuth72488}
          />
          {/* End Purchase Invoices Routes */}
          <PrivateRoute
            path="/settings"
            exact
            moduleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
            Component={Settings}
          ></PrivateRoute>
          <PrivateRoute path="/menu" exact Component={Menu} MenuFilters={{}} />
          <PrivateRoute path="/home" exact Component={Home} MenuFilters={{}} />
          <PrivateRoute path="/onboarding/:onboardingAccount?" exact Component={Onboarding} MenuFilters={{}} />
          <PrivateRoute
            path={AUTH_TENANT_SETUP}
            exact
            Component={TenantSetupScreen}
            MenuFilters={{}}
            websiteIds={[ApplicationInfo.WebsiteId, CapricornApplicationInfo.WebsiteId]}
          />
          <PrivateRoute path="/401" exact component={() => <Unauthorized />} />
          <PrivateRoute path="/404" exact component={() => <NotFound />} />
          <PrivateRoute path="/pay/onboarding/:onboardingAccount?" exact Component={APOnboarding} MenuFilters={{}} />
          <PrivateRoute
            path="/pay/onboardingv1/:onboardingAccount?"
            exact
            Component={APOnboardingV1}
            MenuFilters={{}}
          />
          <PrivateRoute
            path="/module-info/accounts-payable"
            exact
            Component={AccountsPayableModuleInfo}
            MenuFilters={{}}
          />

          <Route path="/supplier">
            <MarketplaceRoutes />
          </Route>

          <MarketplaceRoute
            path="/:marketPlacePath"
            Component={MarketplaceLayout}
            MenuFilters={{
              MenuType: MenuTypes.Buying,
              ModuleID: ModuleTypes.SpendaMarketBuying,
              ModuleDependency: [
                ModuleTypes.SpendaMarketBuying,
                ModuleTypes.SpendaCollectConnectedStatements,
                ModuleTypes.ConnectedPayments,
              ],
            }}
          />

          <PrivateRoute path="/callback/:servicename" exact component={() => <Callback />} />

          <PrivateRoute component={() => <NotFound />} />
        </Switch>
      </PayByLinkProvider>
    </PurchasingState>
  );
};
