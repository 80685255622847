import React from 'react';
import {Button, Dialog, DialogBody, DialogHeader, DialogFooter, Typography, Input, IconButton} from 'spenda-ui-react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface IQuoteAmountPayDialog {
  open: boolean;
  handleOpen: ()=> void;
  handleClose: ()=> void;
}

export const QuoteAmountPayDialog = (props: IQuoteAmountPayDialog) => {
  const {open, handleOpen, handleClose} = props;

  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
    >
      <DialogHeader className="flex flex-col gap-1">
        <div className="flex w-full justify-end">
          <IconButton variant="outlined" onClick={handleClose}>
            <CloseRoundedIcon className="text-primary" />
          </IconButton>
        </div>
        <Typography className="flex w-[261px] items-center justify-center text-center text-black" variant="h2">
          How much would you like to pay as a deposit
        </Typography>
      </DialogHeader>
      <DialogBody className="w-full min-w-[290px] flex flex-col items-center justify-center gap-5">
        <Typography className={`text-black-900 w-[210px] min-w-[210px] font-normal max-w-[210px] text-center`} variant="small">
        There are three options, you can either pay the set deposit amount, pay a custom amount greater than the set deposit or pay the full amount upfront. 
        </Typography>
        <div className='flex flex-col items-center justify-center w-[268px]'>
          <div className='w-[268px] rounded-md bg-[#D3E5EF80] p-2.5'>
            <Typography variant='small' className='flex justify-center items-center text-black-900 font-normal'>Set deposit</Typography>
          </div>
         <Typography variant='small' className='text-black-900 font-normal p-2 mt-1'>$100</Typography>
        </div>
        <Input label='Custom amount' containerProps={{className: 'w-[267px] min-w-[267px]'}}/>
      </DialogBody>
      <DialogFooter>
        <div className="flex w-full items-center justify-center p-2 flex-col">
          <div className="flex flex-col justify-between gap-2 w-[300px]">
            <Button onClick={handleOpen} variant='outlined'>
            Pay Set Deposit Amount
            </Button>
            <Button onClick={handleOpen} variant='outlined'>
            Pay Custom Amount       
          </Button>
            <Button onClick={handleOpen}>
            Pay Full Amount
            </Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
