import React, {useEffect, useMemo, useState} from 'react';
import {Button, SearchInput, Typography} from 'spenda-ui-react';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import clsx from 'clsx';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {useParams} from 'react-router-dom';
import {
  ISupplierManagementData,
  ISupplierManagementResponse,
} from '../../../model/requires-attention/StagedTransactions';
import {PostingTypeModal} from '../../../components/accountsPayableOnboarding/PostingTypeModal';
import {SelectPostingInventoryModal} from '../../../components/accountsPayableOnboarding/SelectPostingInventoryModal';
import {ISupplier} from '../../../model/supplier/Supplier';
import {useSupplierAPI} from '../../../services/useSupplierAPI';

interface ISupplierManagementProps {
  onClickCancelBtn?: () => void;
}

export const SupplierManagement = (props: ISupplierManagementProps) => {
  // Props
  const {onClickCancelBtn} = props;

  // Hooks
  const {supplierId: sId} = useParams<{supplierId?: string}>();

  //States
  const [searchKey, setSearchKey] = useState<string>('');
  const [transactions, setTransactions] = useState<ISupplierManagementData[]>();
  const [selectedTransaction, setSelectedTransaction] = useState<ISupplierManagementData>();
  const [postingSupplierDetails, setPostingSupplierDetails] = useState<ISupplier>();
  const [isShowPostSupplierAsPopup, setIsShowPostSupplierAsPopup] = useState<boolean>(false);
  const [isShowPostInventoryAsPopup, setIsShowPostInventoryAsPopup] = useState<boolean>(false);
  const [postSupplierAsPopup, setPostSupplierAsPopup] = useState<boolean>(false);
  const [postInventoryAsPopup, setPostInventoryAsPopup] = useState<boolean>(false);

  // Apis
  const {getSupplierManagementData, isLoading} = useSupplierTransactionsAPI();
  const {getSupplierById} = useSupplierAPI();

  // Constant
  const supplierId = Number(sId);

  useEffect(() => {
    fetchSupplierManagementData();
  }, [supplierId]);

  const fetchSupplierManagementData = async () => {
    try {
      if (!supplierId) return;
      const response: ISupplierManagementResponse = await getSupplierManagementData(supplierId);
      const result: ISupplierManagementData[] = response.value;
      setTransactions(result);
    } catch (e) {
      console.warn('Error: ', e);
    }
  };

  const fetchSupplierDetails = async () => {
    try {
      const postingSupplierID = selectedTransaction?.postingSupplierID;
      if (!postingSupplierID) return;
      const response = await getSupplierById(postingSupplierID);
      setPostingSupplierDetails(response);
    } catch {}
  };

  const filteredRows = useMemo(() => {
    return transactions
      ?.filter(row => {
        const supplier = row?.vendorName?.toLowerCase() ?? '';
        return supplier.includes(searchKey.toLowerCase());
      })
      .sort((a, b) => {
        const supplierA = a.vendorName?.toLowerCase() ?? '';
        const supplierB = b.vendorName?.toLowerCase() ?? '';
        return supplierA.localeCompare(supplierB);
      });
  }, [transactions, searchKey]);

  const resetState = () => {
    setIsShowPostSupplierAsPopup(false);
    setIsShowPostInventoryAsPopup(false);
    setPostSupplierAsPopup(false);
    setPostInventoryAsPopup(false);
    setSelectedTransaction(undefined);
    setPostingSupplierDetails(undefined);
  };

  const handleUpdateDone = async () => {
    resetState();
    await fetchSupplierManagementData();
  };

  useEffect(() => {
    const fetchDetails = async () => {
      if (selectedTransaction) {
        await fetchSupplierDetails();
      }
    };

    fetchDetails();
  }, [selectedTransaction]);

  useEffect(() => {
    if (postingSupplierDetails) {
      if (postSupplierAsPopup) {
        setIsShowPostSupplierAsPopup(true);
        setIsShowPostInventoryAsPopup(false);
      } else if (postInventoryAsPopup) {
        setIsShowPostInventoryAsPopup(true);
        setIsShowPostSupplierAsPopup(false);
      }
    }
  }, [postingSupplierDetails, postSupplierAsPopup, postInventoryAsPopup]);

  useEffect(() => {
    if (postSupplierAsPopup) {
      setIsShowPostInventoryAsPopup(false);
    }
    if (postInventoryAsPopup) {
      setIsShowPostSupplierAsPopup(false);
    }
  }, [postSupplierAsPopup, postInventoryAsPopup]);

  const columns = [
    {
      title: 'Suppliers',
      key: 'suppliers',
      width: '33%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-[10px]',
      isSortable: false,
      rowRenderer: (rowData: ISupplierManagementData) => (
        <Typography
          data-autoid={`lblSuppliers`}
          variant="paragraph"
          className={clsx(`inline  overflow-ellipsis text-left text-base font-medium text-black-800`)}
        >
          {rowData.vendorName}
        </Typography>
      ),
    },
    {
      title: 'Supplier posting behaviour',
      key: 'supplierPostingBehaviour',
      width: '33%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-[10px]',
      isSortable: false,
      rowRenderer: (rowData: ISupplierManagementData) => (
        <button
          data-autoid={`lblSupplierPostingBehaviour`}
          className={clsx(`inline overflow-ellipsis text-left text-base font-semibold text-primary`)}
          onClick={() => {
            setSelectedTransaction(rowData);
            setPostSupplierAsPopup(true);
          }}
        >
          {rowData?.postingSupplierName}
        </button>
      ),
    },
    {
      title: 'Default inventory posting behaviour',
      key: 'defaultInventoryPostingBehaviour',
      width: '33%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-[10px]',
      isSortable: false,
      rowRenderer: (rowData: ISupplierManagementData) => {
        return (
          <button
            data-autoid={`lblDefaultInventoryPostingBehaviour`}
            className={clsx('inline overflow-ellipsis text-left text-base font-semibold text-primary')}
            onClick={() => {
              setSelectedTransaction(rowData);
              setPostInventoryAsPopup(true);
            }}
          >
            {rowData?.postingSupplierName && rowData?.postingInventoryCode ? rowData?.postingInventoryCode : '-'}
          </button>
        );
      },
    },
  ];

  return (
    <div className={`relative flex h-full w-full flex-col overflow-hidden bg-white pb-16`}>
      <div className={` header flex !min-h-[60px] items-center justify-between border-b border-[#D8D8D8] py-2.5`}>
        <Typography className=" font-poppins text-[22px] font-light text-[#333333]" variant="h2">
          {'Supplier management'}
        </Typography>
      </div>
      <div className="h-full max-w-[66%]">
        <div className="mt-6 flex">
          <Typography className="flex-start mt-2 flex text-sm font-normal text-[#333333]">
            The table below shows the Suppliers listed on your trade account statement, the default inventory item used
            when Spenda posts an invoice to your financial system, and the Supplier record they are linked to in your
            financial system. In the table below you are able to change your default inventory if you wish.
          </Typography>
        </div>
        <div className="my-6 w-[356px]">
          <SearchInput
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
            clearIcon
            clearIconProps={{
              onClick: () => setSearchKey(''),
            }}
            autoFocus
            expanded
            placeholder={'Search by...'}
            reverse={true}
            data-autoid={`btnSearch`}
          />
        </div>
        <div
          data-autoid={`tblSupplierManagement`}
          className={`invoicesList flex h-[calc(100%-200px)] w-full flex-col overflow-y-auto bg-white pb-10`}
        >
          <ARTable
            isPaginated
            dataAutoId={`supplierManagement`}
            isLoading={isLoading}
            columns={columns}
            rows={filteredRows || []}
          />
        </div>
      </div>
      <div className="footer absolute bottom-[10px] flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-4">
        <Button
          onClick={() => onClickCancelBtn?.()}
          data-autoid={`btnCancel`}
          variant="outlined"
          color="primary"
          className="bg-white"
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" data-autoid="btnSave" loading={false}>
          Save
        </Button>
      </div>
      {isShowPostSupplierAsPopup && selectedTransaction && (
        <PostingTypeModal
          isVendorPostingType
          selectedSupplierDetail={postingSupplierDetails}
          selectedTransactionDetail={selectedTransaction}
          handleClose={() => resetState()}
          handleDone={handleUpdateDone}
          scope="PSBL"
          isReMapping={true}
        />
      )}
      {isShowPostInventoryAsPopup && (
        <SelectPostingInventoryModal
          isVendorSelected
          selectedSupplierDetail={postingSupplierDetails}
          selectedTransactionDetail={selectedTransaction}
          // selectedVendorData={selectedStagedTransaction}
          handleClose={() => resetState()}
          handleDone={handleUpdateDone}
          scope="PSBL"
          isReMapping={true}
        />
      )}
    </div>
  );
};
