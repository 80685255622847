import React, {useContext, useEffect} from 'react';
import {Route, RouteComponentProps, Redirect, Switch} from 'react-router-dom';
import {IPrivateRouteProps} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {ParamentersToSelectMenu} from '../model/tenant/TenantModuleDetails';
import {FeatureFlagKeys, useFeatureFlags} from '../hooks/useFeatureFlags';
import AppContext from '../context/app/appContext';
import {Order} from '../screens/purchasing/Order';
import {SupplierInvoice} from '../screens/purchasing/SupplierInvoice';
import {PSBLSettings} from '../screens/AccountsReceivable/settings/PSBLSettings';
import {PrePaymentWidget} from '../screens/purchasing/PrePaymentsWidget';
import CreateAndEditClaimRequest from '../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import {ClaimsAndReturns} from '../screens/AccountsReceivable/claims-and-returns/ClaimsAndReturns';
import ARPaymentsScreen from '../screens/AccountsReceivable/payments/ARPaymentsScreen';
import {PaidInvoices} from '../screens/AccountsReceivable/paid-invoices/PaidInvoices';
import {RequiresAttention} from '../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {PSBLSettingsV2} from '../screens/AccountsReceivable/settings/PSBLSettingsV2';
import PSBLModuleInfo from '../screens/module-info/PSBLModuleInfo';
import PSBLOnboarding from '../screens/onboarding/PSBLOnboarding';
import {CapNonPilotMemberNotAllowedDialog} from '../components/AccountsReceivable/modals/CapNonPilotMemberNotAllowedDialog';
import {isWebsiteId17} from '../config';

interface IMarketplaceRouteProps extends IPrivateRouteProps {
  supplierModuleIds?: ModuleTypes[];
  MenuFilters?: ParamentersToSelectMenu;
}

export const MarketplaceRoute = ({
  path,
  supplierModuleIds,
  Component,
  exact = false,
  MenuFilters,
  featureFlag,
  ...rest
}: IMarketplaceRouteProps) => {
  const {isFlagEnabledInSupplier} = useFeatureFlags();
  const {user: currentUser, suppliers, setMenu, marketplaceSupplier, tenantInfo} = useContext(AppContext);

  useEffect(() => {
    if (MenuFilters) {
      setMenu(MenuFilters);
    }
  }, [path, marketplaceSupplier?.SupplierID]);

  const {IsAuthorisedToAccessWebsite} = tenantInfo?.TenantUserDetails || {};

  // If we are in LMI context which has website ID 17 and user is not in the allowed list then showing the not allowed pop
  if (
    isWebsiteId17 &&
    IsAuthorisedToAccessWebsite === false &&
    isFlagEnabledInSupplier(FeatureFlagKeys.PSBLSTAGING) &&
    !isFlagEnabledInSupplier(FeatureFlagKeys.lmiCommercialRelease92671)
  ) {
    return <CapNonPilotMemberNotAllowedDialog />;
  }

  return (
    <Route
      {...rest}
      exact={exact}
      render={(
        props: RouteComponentProps<{
          supplierId?: string;
          marketPlacePath?: string;
        }>,
      ) => {
        if (!currentUser || !currentUser.Modules) {
          return;
        }

        if (!Component) {
          return <Redirect to="/404" />;
        }

        if (!supplierModuleIds) {
          return <Component {...props} />;
        }

        if (props.match.params.marketPlacePath) {
          return <Component {...props} />;
        }

        const supplierId = props.match.params.supplierId;

        if (supplierId && suppliers.length > 0) {
          const mpsupplier = suppliers?.find(s => s.SupplierID == parseInt(supplierId));
          if (!mpsupplier) {
            return <Redirect to="/401" />;
          }

          const supplierHasRequiredModule = supplierModuleIds.some(m => mpsupplier.AvailableModules?.indexOf(m) >= 0);
          if (!supplierHasRequiredModule) {
            return <Redirect to="/401" />;
          }
        }

        // Feature Flag is not enabled for this Route
        if (featureFlag && !isFlagEnabledInSupplier(featureFlag)) {
          return <Redirect to="/menu" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export const MarketplaceRoutes = () => {
  return (
    <Switch>
      <MarketplaceRoute
        path="/supplier/module-info/psbl"
        exact
        Component={PSBLModuleInfo}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/psbl/onboarding/:onboardingAccount?"
        exact
        Component={PSBLOnboarding}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/order"
        exact
        Component={Order}
        supplierModuleIds={[ModuleTypes.SpendaMarketBuying]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/bills"
        exact
        Component={SupplierInvoice}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/payment-history"
        exact
        key={'arPayments'}
        Component={ARPaymentsScreen}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/prepayment"
        exact
        Component={PrePaymentWidget}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
        featureFlag={FeatureFlagKeys.prepayments72511}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/payment-options"
        exact
        Component={PSBLSettings}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/bills/:transactionId"
        exact
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        component={SupplierInvoice}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/payment-history/:transactionId"
        exact
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        component={SupplierInvoice}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/claim-request"
        exact
        Component={ClaimsAndReturns}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
        featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/paid-invoices"
        exact
        key={'paidInvoices'}
        Component={PaidInvoices}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path={'/supplier/:supplierId/requires-attention'}
        exact
        key={'requiresAttention'}
        Component={RequiresAttention}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/settings"
        exact
        Component={PSBLSettingsV2}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/claim-request/create"
        exact
        Component={CreateAndEditClaimRequest}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
        featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
      />
      <MarketplaceRoute
        path="/supplier/:supplierId/claim-request/edit/:claimRequestId"
        exact
        Component={CreateAndEditClaimRequest}
        supplierModuleIds={[ModuleTypes.SpendaCollectConnectedStatements]}
        MenuFilters={{
          MenuType: MenuTypes.Buying,
          ModuleID: ModuleTypes.SpendaMarketBuying,
          ModuleDependency: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
        }}
        featureFlag={FeatureFlagKeys.claimAndReturnsV272602}
      />
    </Switch>
  );
};
