import React, {useCallback, useEffect, useState} from 'react';

import {List, ListItem, Radio, Typography, Upload, Button, Select, IconButton, Option, Input} from 'spenda-ui-react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';

import {RefreshIcon} from '../../assets/svg/RefreshIcon';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

import {IAccounts} from '../../model/accounts/Accounts';
import useAccountAPI from '../../services/useAccountAPI';

interface FormValues {
  validityPeriod: string;
  depositAmountType: string;
  depositAmount: number;
  liabilityAccount: string;
  termsAndConditions: File | null;
}
interface RadioListItemProps {
  id: string;
  name: string;
  label: string;
  value: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: FormValues;
  touched: Partial<Record<keyof FormValues, boolean>>;
  errors: Partial<Record<keyof FormValues, string>>;
}

interface SectionProps {
  title: string;
  items: Omit<RadioListItemProps, 'handleChange' | 'values' | 'touched' | 'errors'>[];
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: FormValues;
  touched: Partial<Record<keyof FormValues, boolean>>;
  errors: Partial<Record<keyof FormValues, string>>;
}

const RadioListItem: React.FC<RadioListItemProps> = ({id, name, label,value, handleChange, values, touched, errors}) => (
  <div className="flex flex-col gap-2">
    <ListItem className="flex w-full items-center px-3 py-2">
      <Radio
        color="primary"
        name={name}
        id={id}
        ripple={false}
        className="hover:before:opacity-0"
        containerProps={{className: 'p-0'}}
        label={label}
        value={value}
        onChange={e => handleChange(e)}
        labelProps={{className: 'font-poppins font-medium text-black-800 ml-2.5'}}
        defaultChecked={values[name as keyof FormValues] === value}
      />
    </ListItem>
    {value == 'percentage' && (
      <Input
        data-autoid={`txtPercentageValue${id}`}
        label="Add Value"
        type="number"
        name="depositAmount"
        placeholder="0.0"
        containerProps={{className: 'ml-12 w-[126px] min-w-[126px]'}}
        InputProp={{
          endAdornment: <span className="text-primary">%</span>,
        }}
        maxLength={3}
        onChange={handleChange}
        value={values.depositAmount}
        helperText={touched.depositAmount && errors.depositAmount ? errors.depositAmount : ''}
        error={Boolean(touched.depositAmount && errors.depositAmount)}
      />
    )}
  </div>
);

const Section: React.FC<SectionProps> = ({title, items, handleChange, values, touched, errors}) => (
  <div>
    <Typography variant="h3">{title}</Typography>
    <List>
      {items.map((item, index) => (
        <RadioListItem
          key={index}
          {...item}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
        />
      ))}
    </List>
  </div>
);

const validationSchema = Yup.object().shape({
  liabilityAccount: Yup.string().required('Liability account is required'),
  termsAndConditions: Yup.mixed().required('Terms and Conditions file is required'),
  depositAmount: Yup.number().when('depositAmountType', {
    is: (value: string) => value === 'percentage',
    then: Yup.number()
      .required('Required')
      .min(0, 'Value must be greater than or equal to 0')
      .max(100, 'Value must be less than or equal to 100'),
  }),
});

export const CustomisationSettingsForm = () => {
  const [liabilityAccount, setLiabilityAccount] = useState<IAccounts[]>([]);
  const [selectedLiabilityAccount, setSelectedLiabilityAccount] = useState<string | undefined>(undefined);

  const {getCreditlineAccounts} = useAccountAPI();

  const getChartAccounts = useCallback(() => {
    getCreditlineAccounts().then((data: IAccounts[]) => {
      setLiabilityAccount(data);
    });
  }, [getCreditlineAccounts]);

  useEffect(() => {
    getChartAccounts();
  }, []);

  const validityPeriodItems = [
    {id: '7', name: 'validityPeriod', label: '7 days', value: '7'},
    {id: '14', name: 'validityPeriod', label: '14 days', value: '14'},
    {id: '21', name: 'validityPeriod', label: '21 days', value: '21'},
    {id: '30', name: 'validityPeriod', label: '30 days', value: '30'},
  ];

  const depositAmountItems = [
    {id: 'percentage', name: 'depositAmountType', label: 'Percentage', value: 'percentage'},
    {id: 'flat-fee', name: 'depositAmountType', label: 'Flat Fee', value: 'flat-fee'},
    {id: 'no-deposit', name: 'depositAmountType', label: 'No Deposit', value: 'no-deposit'},
  ];

  return (
    <Formik<FormValues>
      initialValues={{
        validityPeriod: '14',
        depositAmountType: 'percentage',
        depositAmount: 0.0,
        liabilityAccount: '',
        termsAndConditions: null,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({handleChange, values, touched, errors, handleSubmit}) => (
        <Form onSubmit={handleSubmit} className="relative w-full">
          <div className="absolute right-0 mt-[-60px] flex justify-end">
            <Button type="submit" data-autoid="btnSave">
              Save <LoadingIndicator size="sm" color="#FFF" position={{right: '5px'}} />
            </Button>
          </div>
          <div className="h-[calc(100vh-210px)] overflow-y-auto">
            <div className="ml-3 mt-6 flex flex-col gap-4 font-poppins text-black-800">
              <Section
                title="Set a default validity period for quotes"
                items={validityPeriodItems}
                handleChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
              />
              <Section
                title="Set a default for the deposit amount"
                items={depositAmountItems}
                handleChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
              />
              <div>
                <Typography variant="h3" className="text-black-800">
                  Upload your own Terms and Conditions
                </Typography>
                <Typography variant="small" className="text-black-800">
                  This will be attached to the quote package and must be agreed upon by the customer before accepting
                  the quote.
                </Typography>
                <Upload
                  data-autoid="uploadAttachment"
                  label="Drop any additional documents here or click to upload"
                  className="my-6 h-full min-h-[114px] max-w-[477px] text-sm font-normal text-black-800"
                  accept=".pdf"
                />
              </div>
            </div>
            <div className="mb-10 ml-3 mt-5 flex flex-col gap-4">
              <div>
                <Typography variant="h3" className="text-lg font-medium text-black-800">
                  Set up liability account for the prepayment
                </Typography>
              </div>
              <div className="flex gap-6">
                <Typography variant="small" className="flex items-center justify-center font-normal text-black-800">
                  Select liability account(deposit value):
                </Typography>
                <div className="flex flex-row items-center">
                  <div className={`flex items-center`}>
                    <Select
                      label="Select Account"
                      value={selectedLiabilityAccount}
                      onChange={() => setSelectedLiabilityAccount(selectedLiabilityAccount)}
                      name="liabilityAccount"
                      className="w-[224px]"
                      menuProps={{className: 'max-h-48'}}
                    >
                      {liabilityAccount.map(({Name, Code, ID}) => (
                        <Option key={ID} value={Name.toString()}>
                          <Typography
                            data-autoid={`txtAccount-${ID}`}
                            variant="small"
                            className="font-semibold text-black-800"
                          >
                            {Code} - {Name}
                          </Typography>
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <IconButton variant="text" name="refresh" onClick={getChartAccounts}>
                    <RefreshIcon height="18px" width="18px" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
